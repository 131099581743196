@import '../../../styles/customMediaQueries.css';

.sortBy {
  margin-right: 9px;
}

.sortByDesktop {
  margin-left: auto;
}

.sortByMenuLabel {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  min-width: 50px;
  height: 35px;
  min-height: 35px;

  padding: 0 12px;
  margin: 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Font */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  
  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Button borders */
  border: 1px solid var(--customGrayBorder);
  border-radius: var(--borderRadiusMedium);

  /* background-color: var(--colorWhite); */

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
    border-color: var(--customBlack);
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  @media (--viewportMedium) {
    min-width: 120px;
    height: auto;
    min-height: 0;
    padding: var(--marketplaceButtonSmallDesktopPadding);
    margin: 0;

    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 20px;

    &:hover,
    &:focus {
      border-color: var(--customBlack);
    }
  }

  & svg {
    margin-right: 10px;
  }
}
