@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupAmenities {
  
  @media (--viewportMedium) {
    display: flex;
  }

  & .list {
    min-width: 200px;
    margin: 20px 0 20px 0;

    @media (--viewportMedium) {
      margin: 0 20px 0 0;
    }
  }
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 12px;
  padding-bottom: 6px;
  border: none;

  @media (--viewportMedium) {
    padding: 3px 0 29px 12px;
    padding: 4px 0 4px 12px;
  }
}

.list {
  margin: 0;
}

.listTitle {
  font-weight: var(--fontWeightBold);
  margin-bottom: 10px;
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}
